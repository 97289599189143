import "@fortawesome/fontawesome-free/css/all.css";
import Vue from "vue";
// import Vuetify from 'vuetify/lib/framework';
import Vuetify, {
  VBtn,
  VIcon,
  VRow,
  VCol,
  VApp,
  VAppBar,
  VMain,
  VContainer,
  VCard,
} from "vuetify/lib";

Vue.use(Vuetify, {
  components: {
    VBtn,
    VIcon,
    VApp,
    VAppBar,
    VMain,
    VContainer,
    VRow,
    VCol,
    VCard,
  },
  icons: {
    iconfont: "fa",
  },
});

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#000000",
      },
    },
  },
};

export default new Vuetify(opts);
