export default {
    state: {
        reportResponse: null,
        authenticated: false
    },

    getters: {},

    mutations: {
        resetState(state) {
            state.reportResponse = null
        },
        addReport(state, report) {
            state.reportResponse = report
        },
        authenticate(state) {
            state.authenticated = true
        }
    }
}