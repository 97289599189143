<template>
  <v-app class="main">
        <BBSnackbar/>
        <router-view />
  </v-app>
</template>

<script>
import BBSnackbar from './components/BBSnackbar.vue'

export default {
  name: 'App',
  components: {
    BBSnackbar
  }
}
</script>

<style>
.main {
    color: #424752;
    padding-top: 100px !important;
}
</style>
