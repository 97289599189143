<template>
  <v-snackbar-queue
    :items="items"
    @remove="removeItem"
    :timeout="2000"
    closeButtonIcon="mdi-close"
    nextButtonCountText="+"
    nextButtonText=""
    class="snack-bar"
  >
  </v-snackbar-queue>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message: '',
      items: [],
    };
  },
  created: function() {
    this.$store.watch(
      (state) => state.snacktext,
      () => {
        let msg = this.$store.state.snacktext;
        if (msg !== "") {
          let id = Date.now();
          this.show = true;
          this.items.push({
            id: id,
            message: this.$store.state.snacktext.text + " " + this.$store.state.snacktext.message,
            color: this.$store.state.snacktext.color
          });
          this.message = this.$store.state.snacktext.text;
          this.$store.commit("setSnack", "");
        }
      }
    );
  },
  methods: {
    removeItem(id) {
      let find = this.items.findIndex((item) => item.id === id);

      if (find !== -1) {
        this.items.splice(find, 1);
      }
    },
  },
};
</script>
