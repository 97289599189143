import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import BBSnackbar from './components/BBSnackbar';
import i18n from "./i18n";

import VueLodash from "vue-lodash";
import lodash from "lodash";

import VuetifySnackbarQueue from "vuetify-snackbar-queue";
Vue.use(VueLodash, { lodash: lodash });
Vue.config.productionTip = false;

function lazyLoad(item) {
  return () => import(`@/${item}.vue`);
}

import routes from "./data/routes.json";

async function testRoutes() {
  // let test = await axios.get(process.env.VUE_APP_CMS_ENDPOINT + "/routes");
  let test = routes;
  // test.data.map((e) => {
  test.map((e) => {
    router.addRoutes([
      { path: e.path, name: e.name, component: lazyLoad(e.component) },
    ]);
  });
}

testRoutes();

axios.defaults.withCredentials = true;

Vue.use(VueAxios, axios);

Vue.use(VuetifySnackbarQueue);

Vue.component("bb-snackbar", BBSnackbar);

Vue.mixin({
  methods: {
    lang(text) {
      return store.getters.lang(text);
    }
  },
});

// Vue.use(VueAnalytics, {
//   id: "UA-176890210-3",
//   router,
// });

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  i18n,
}).$mount("#app");
