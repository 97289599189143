import Vuex from 'vuex'
import Vue from "vue";
import organization from "./modules/organization";
import report from './modules/report'

Vue.use(Vuex)

export default new Vuex.Store({

  state: {
    whatHappened: '',
    whenHappened: '',
    whereHappened: '',
    moreInformation: '',
    addContactInfo: false,
    addNotificationEmail: false,
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    notificationEmail: '',
    addedFiles: [],
    sendedFiles: null,
    name: "example",
    sended: false,
    reportResponse: null,
    snacktext: {},
    channelId: "",
    groupId: "",
  },

  mutations: {
    updateReport(state, formData) {
      state.whatHappened = formData.whatHappened
      state.whenHappened = formData.whenHappened
      state.whereHappened = formData.whereHappened
      state.moreInformation = formData.moreInformation
    },
    toggleAddContactInfo(state) {
      state.addContactInfo = !state.addContactInfo
    },
    toggleAddNotificationEmail(state) {
      state.addNotificationEmail = !state.addNotificationEmail
    },
    updateContactInfo(state, formData) {
      state.contactName = formData.name
      state.contactEmail = formData.email
      state.contactPhone = formData.phone
    },
    updateNotificationEmail(state, email) {
      state.notificationEmail = email
    },
    updateAddedFiles(state, files) {
      state.addedFiles.push(files)
    },
    updateSendedFiles(state, files) {
      state.sendedFiles = files
    },
    deleteFile(state, index) {
      if(index>-1) {
        state.addedFiles.splice(index,1)
      }
    },
    updateReportResponse(state, response) {
      state.reportResponse = response
    },
    updateChannelId(state, id) {
      state.channelId = id
    },
    updateGroupId(state, id) {
      state.groupId = id
    },
    resetState(state) {
      state.whatHappened = '',
      state.whenHappened = '',
      state.whereHappened = '',
      state.moreInformation = '',
      state.addContactInfo = false,
      state.addNotificationEmail = false,
      state.contactName = '',
      state.contactEmail = '',
      state.contactPhone = '',
      state.notificationEmail = '',
      state.addedFiles = [],
      state.sendedFiles = null,
      state.name = "example",
      state.sended = false,
      state.channelId = "",
      state.groupId = ""
    },
    setSnack(state, text) {
      state.snacktext = text;
    },
  },
  getters: {
    getName() {
        return this.state.name
    }
  },
  modules: {
    organization,
    report
  }
})
