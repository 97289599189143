import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue'
import Etusivu from "../views/etusivu.vue";
import store from "../store/modules/organization.js"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Etusivu,
  },
  {
    path: "/:organization",
    name: "organization",
    component: () => import("../views/aloita.vue"),
  },
  {
    path: "/:organization/lomake",
    name: "lomake",
    component: () => import("../views/lomake.vue"),
    meta: { requiresAuth: true },
    
  },
  {
    path: "/:organization/yhteystiedot",
    name: "yhteystiedot",
    component: () => import("../views/yhteystiedot.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/:organization/yhteenveto",
    name: "yhteenveto",
    component: () => import("../views/yhteenveto.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/:organization/vahvistus",
    name: "vahvistus",
    component: () => import("../views/vahvistus.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/login/report",
    name: "kirjaudu",
    component: () => import("../views/kirjaudu.vue")
  },
  {
    path: "/login/tarkastele",
    name: "tarkastele",
    component: () => import("../views/tarkastele.vue")
  }
];

const router = new VueRouter({
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else if (savedPosition) {
      return {
        savedPosition,
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.found) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
  // to and from are both route objects. must call `next`.
});
export default router;
