<template>
    <div class="locale-changer">
        <!-- <div v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
            <v-btn @click="$i18n.locale = lang" v-if="$i18n.locale != lang">{{lang}}</v-btn>
        </div> -->
        <v-btn v-if="$i18n.locale == 'en'" @click="$i18n.locale = 'fi'" class="language-switcher mt-2" depressed >fi</v-btn>
        <v-btn v-if="$i18n.locale == 'fi'" @click="$i18n.locale = 'en'" class="language-switcher mt-2" depressed >en</v-btn>
    </div>
</template>

<style scoped>
    .locale-changer {
        position: static;
        text-align: right;
    }
    
</style>


<script>
export default {
    name: 'locale-changer',
    data () {
        return { langs: ['en', 'fi'] }
    }
}
</script>
