<template>
  <div class="text-center mx-auto" style="max-width: 800px">
      <LocaleChanger/>
      <ReportLander/>
  </div>
</template>

<script>
import ReportLander from '../components/ReportLander.vue'
import LocaleChanger from '../components/LocaleChanger.vue'
export default {
    components: {
        ReportLander,
        LocaleChanger
    },
}
</script>

