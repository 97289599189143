export default {
    state: {
        name: "",
        orgId: "",
        urlName: "",
        image: null,
        found: false,
        s3: "",
        defaultChannel: null,
        groups: null,
        channelList: null
    },

    getters: {},

    mutations: {
        resetState(state) {
            state.org = null
        },
        mapOrganization(state, org) {
            state.name = org.name
            state.orgId = org.orgId
            state.image = org.logo,
            state.found = true;
            state.urlName = org.urlName
            state.s3 = org.bucketName
            state.defaultChannel = org.defaultChannel
            state.channelList = org.channels
            state.groups = org.groups
        }
    }

}